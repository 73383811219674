import React from "react"
import { Link } from "gatsby"
import StandardLayout from "../layouts/standard"
import PageCover from "../components/page-cover"
import decorativeLaminates from "../images/dnd/dl.png"
import specialSurfaces from "../images/dnd/special-surfaces.png"
import stylamIcon from "../images/dnd/stylam-icon.png"
import puCoating from "../images/dnd/pu-coating.png"
import SpecialSurfaces from "../components/SpecialSurfaces"
import CompactLaminates from "../components/CompactLaminates"
import PULacquer from "../components/PULacquer"
import DecorativeLaminates from "../components/DecorativeLaminates"
import SolidSurface from "../components/SolidSurface"
import coverImage from "../images/dnd/design-cover.jpg"
import fascia from "../images/dnd/fascia.jpg"

export default function Designers(props) {
  return (
    <StandardLayout title="Designers">
      <div>
        <PageCover
          coverImage={coverImage}
          title="DESIGNERS"
          description=""
        ></PageCover>
      </div>
      <section className="uk-section">
        <div className="uk-container  uk-margin-large">
          <div className="uk-card uk-card-default uk-padding-small">
            <div class="uk-inline">
              <img src={fascia} alt="" />
              <div class="uk-overlay uk-overlay-primary uk-position-bottom">
                <div className="uk-flex uk-flex-between">
                  <h2 className="uk-h2 uk-padding-remove">
                    FASCIA - EXTERIOR CLADDING
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SolidSurface />
        <div className="uk-container uk-margin">
          <div>
            <h1 className="uk-h1 color-primary uk-text-uppercase uk-text-center">
              LAMINATES
            </h1>
          </div>
        </div>

        <DecorativeLaminates />

        <SpecialSurfaces />

        <PULacquer />
        <div className="uk-container uk-margin">
          <div>
            <h1 className="uk-h1 color-primary uk-text-uppercase uk-text-center">
              PRELAM BOARDS
            </h1>
          </div>
        </div>
        <CompactLaminates />
      </section>
    </StandardLayout>
  )
}
